/*  Web font Included */
@font-face {
  font-family: 'Omnes';
  src: url('../fonts/Omnes-Regular.eot');
  src: url('../fonts/Omnes-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Omnes-Regular.woff2') format('woff2'),
      url('../fonts/Omnes-Regular.woff') format('woff'),
      url('../fonts/Omnes-Regular.ttf') format('truetype'),
      url('../fonts/Omnes-Regular.svg#Omnes-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Omnes';
  src: url('../fonts/Omnes-Bold.eot');
  src: url('../fonts/Omnes-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Omnes-Bold.woff2') format('woff2'),
      url('../fonts/Omnes-Bold.woff') format('woff'),
      url('../fonts/Omnes-Bold.ttf') format('truetype'),
      url('../fonts/Omnes-Bold.svg#Omnes-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Global Properties */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Omnes';
}
img{
  max-width: 100%;
}
button {
cursor: pointer;
}

.mobile-toggle{
  display: none;
}

/* my custom style starts */
.walletConnectOptionsList div {
  color: #ddd;
}

.ReactModal__Overlay {
  background: #00000078 !important;
}
.walletConnectOptionsList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.walletConnectOptionsList button {
  width: 200px;
  padding: 10px;
  margin-bottom: 10px;
  background: #ff6000;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.ReactModal__Content {
  background: #000000c7 !important;
  border: none !important;
}

.logo {
  position: relative;
}

.walletConnected {
  position: absolute;
  display: flex;
  right: 0;
  flex-direction: column;
}

.walletConnected span {
  color: #fff;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}

.walletConnected button {
  width: 100px;
  height: 25px;
  font-size: 14px;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
}

.steps-head {
  margin-bottom: 20px;
  color: #fff;
  font-size: 25px;
}

.steps-wrap {
  color: #fff;
  display: flex;
  margin-bottom: 25px;
}

.steps-media {
  align-self: center;
  padding: 10px;
  width: 50px;
  display: flex;
  height: 40px;
  justify-content: center;
}

span.tick {
  display: block;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #2f2d2d;
  width: 30px;
  height: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 9999;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.steps-infor h4 {
  font-size: 25px;
}

.steps-infor h5 {
  font-weight: 500;
  font-size: 18px;
  margin-top: 5px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.cusrorPointer {
  cursor: pointer;
}

.linktoexplorer a {
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 800;
}

.linktoexplorer {
  text-align: center;
  margin: 20px 0px;
}

a.none_style_CRP {
  color: #000;
  text-decoration: none;
}

.Error_message {
  max-width: 300px;
  margin: 10px 10px;
  color: red !important;
}

/* my custom style ends */

.toggle-menu{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 35px;
  height: 35px;
  border: none;
  background: transparent;
  z-index: 9;
}
.toggle-menu::before,
.toggle-menu::after{
  position: absolute;
  content: '';
  width: 35px;
  height: 1px;
  background-color: #fff;
  display: block;
  opacity: 1;
  margin-top: -6px;
  transition-property: margin, transform;
  transition-duration: .2s;
}
.toggle-menu::after{
  margin-top: 6px;
}
.toggle-menu.open::before{
  margin-top: 0;
  transform: rotate(45deg);
  background-color: #000;
}
.toggle-menu.open::after{
  margin-top: 0;
  transform: rotate(-45deg);
  background-color: #000;
}
.container {
    max-width: 1350px;
    margin: 0 auto;
    padding: 0px 20px;
}
.banner-section{
  background-color: #ff6000;
  padding-bottom: 60px;
}
.header {
    padding: 3vw 0;
    position: relative;
}
.logo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.logo a {
    display: inline-flex;
}
.navbar {
    display: flex;
    justify-content: center;
    margin: 1.5vw 0 0;
}
.navbar ul li {
    list-style: none;
}
.navbar ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    padding: 3px 0;
    position: relative;
}
.navbar ul li a.active::after{
  content: "";
  border-bottom: 1px solid #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.animated-img {
    display: flex;
    width: 100%;
    justify-content: center;
}

.banner-content .text{
  margin: 5rem 0;
}
.banner-content .text p {
    font-size: 45px;
    font-weight: 606;
    margin: 2rem 0;
    text-align: center;
}
.economy-section {
    background: #000;
    padding: 45px 0 50px;
}
.economy-section h2 {
    font-size: 48px;
    color: #ff6000;
    text-transform: uppercase;
    max-width: 760px;
    text-align: center;
}
.primary-btn{
  color: #ff6000;
  padding: 21px 50px;
  background-color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  width: auto;
  margin: auto;
  display: block;
  border-radius: 40px;
  border: none;
  outline: none;
}
.ad-img {
  padding: 0 6.4vw;
}
.zombie-need-section h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 52px;
  text-align: center;
  margin-bottom: 2.5rem;
}
.zombie-need-section p {
  font-size: 29px;
  text-transform: uppercase;
  max-width: 938px;
  margin: 0 auto;
  text-align: center;
  margin-top: 18px;
  line-height: 48px;
}
.zombie-need-section {
  padding: 6.6vmax 0;
  background: #ff6000;
}
.term-fo-use-section {
  padding: 6.6vmax 0;
  background: #e9ceb0;
}
.terms-heading h2 {
  font-size: 45px;
  text-transform: uppercase;
  margin-bottom: 4rem;
}
.terms-description p {
  font-size: 24px;
  margin-bottom: 18px;
  line-height: 36px;
}
.footer {
  background: #000;
  padding: 6.6vmax 0;
}
.copyright-text p {
  font-size: 18px;
  color: #ff6000;
}

/* Team Page style start */
.meet-out-team {
  margin: 6rem 0 3rem;
}
.meet-out-team h1 {
  font-size: 74px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  margin-bottom: 70px;
}
.member-img img {
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
  max-width: 300px;
}
.team-item {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}
.team-item h6 {
  font-size: 22px;
  text-transform: uppercase;
  color: #fff;
  margin: 20px 0;
}
.team-item p {
  font-size: 16px;
  color: #fff;
}


/* Media for (Desktop) */
@media (min-width: 767px) {
  .row {
    display: flex;
  }
  .column-3 {
    width: 25%;
  }
  .column-4 {
    width: 33.33%;
  }
  .column-5 {
    width: 37%;
  }
  .column-7 {
    width: 63%;
  }
  .column-9 {
    width: 70%;
  }
  .team-item{
    max-width: 330px;
  }
  .terms-description {
    padding-right: 9rem;
  }
}
@media (max-width: 768px) {
  .container{
    padding: 0 15px;
  }
  .banner-content .text p{
    font-size: 28px;
  }
  .banner-content .text{
    margin: 5rem 0 3rem;
  }
  .primary-btn{
    padding: 15px 45px;
  }
  .economy-section h2,
  .term-fo-use-section h2{
    font-size: 32px;
    margin-bottom: 20px;
  }
  .ad-img{
    margin-top: 30px;
    padding: 0 4rem;
  }
  .zombie-need-section h2{
    font-size: 32px;
  }
  .zombie-need-section p{
    font-size: 20px;
    line-height: 30px;
  }
  .term-fo-use-section p{
    font-size: 20px;
    line-height: 30px;
  }
  .meet-out-team h1{
    font-size: 40px;
  }
  .meet-out-team h1{
    margin-bottom: 0;
  }
  .team-item{
    margin-top: 70px;
  }
  .logo img{
    max-width: 230px;
  }
  .mobile-toggle{
    display: block;
  }
  .navbar{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    margin: 0;
    align-items: center;
    z-index: 1;
    display: none;
  }
  .navbar.show{
    display: flex;
  }
  .navbar ul li a{
    color: #000;
  }
  .navbar ul li a.active::after{
    border-color: #000;
  }
}